import classNames from 'classnames';
import * as React from 'react';
import celebrateImage from '../../../assets/images/celebrate.svg';
import identityImage from '../../../assets/images/identity.svg';
import verificationImage from '../../../assets/images/verification.svg';
import styles from './index.module.scss';
import Navbar from '../../components/Navbar';
import Head from '../../components/Head';
import Footer from '../../components/Footer';
import LearnMore from '../../components/LearnMore';
import Hero from '../../components/Hero';
import heroImage from '../../../assets/images/solutions/estateagents.svg';

const UseCaseEstateAgent: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.Solution}>
      <Head title='Get more instructions through integrated review management | Opinous for Estate Agents' />
      <Navbar />
      <Hero graphic={heroImage} narrow={true}>
        <h1>
          <span className={styles.For}>Opinous For</span>Estate Agents
        </h1>
        <h2>
          Estate Agency today is more competitive than ever before which means your business needs
          to take every advantage.
          <br />
          <br />
          Our powerful, easy to use Review Management platform helps you to drive more instructions
          by helping you to collect, manage and publish customer reviews.
        </h2>
      </Hero>
      <section className={styles.Story}>
        <div className={styles.SectionTitle}>
          <h2>Get more Instructions for your Estate Agency with Opinous</h2>
        </div>
        <div className={styles.Part}>
          <div className={styles.Image}>
            <img src={verificationImage} />
          </div>
          <div className={styles.Body}>
            <h3>Integrate with the tools you already have</h3>
            <p>
              Opinous integrates with the tools you are already using to run your Estate Agency such
              as HubSpot to automatically invite all of your customers to leave feedback.
            </p>
            <br />
            <p>
              We also provide attractive physical cards that you can give to your customers in
              person that allow them to easily leave a review.
            </p>
          </div>
        </div>
        <div className={classNames(styles.Part, styles.Reverse)}>
          <div className={styles.ImageMobile}>
            <img src={identityImage} />
          </div>
          <div className={styles.Body}>
            <h3>Protect your business from damaging reviews</h3>
            <p>
              In estate agency there will always be unhappy customers. If left unanswered these
              reviews present a negative impression of your business across the internet.
            </p>
            <br />
            <p>
              Opinous provides a complete toolset for professionally responding to negative reviews
              in public whilst flagging false or abusive reviews for removal.
            </p>
          </div>
          <div className={styles.Image}>
            <img src={identityImage} />
          </div>
        </div>
        <div className={styles.Part}>
          <div className={styles.Image}>
            <img src={celebrateImage} />
          </div>
          <div className={styles.Body}>
            <h3>Leverage your happy customers to get more instructions</h3>
            <p>
              Reviews are an essential component in how your business is ranked in online search
              results as well as providing a first impression to potential customers.
            </p>
            <br />
            <p>
              Opinous integrates with Google My Business to make sure your business is ranking
              highly for searches in your area as well as providing a great digital presence for
              your business.
            </p>
          </div>
        </div>
      </section>
      <LearnMore />
      <Footer />
    </div>
  );
};

export default UseCaseEstateAgent;
